import React,{useEffect} from 'react';
import './App.css';
import { Helmet } from 'react-helmet'
import logo from './logo.png';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';

function App() {
  useEffect (()=>{

  })
  return (
    <div className="App">
      <Helmet><title>
        共栄畜産
        </title></Helmet>
        <Drawer
        variant="permanent"
        anchor="left"
        >
        <List>
          <ListItem>
            <ListItemText>
              沿革
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              会社概要
            </ListItemText>
          </ListItem>
        </List>
        </Drawer>
        <div style={{padding:30}}>
        <img src={logo} alt="共栄畜産" style={{width:500, height:'auto'}}/>
        </div>
       <Typography variant="h4">準備中</Typography>
    </div>
  );
}

export default App;
